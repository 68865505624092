.downloadContainer {
    position: relative;
    font-family: var(--font-primary);
    padding: 18px 0px 4.6vw;
    color: white;
}

.styledRow {
    width: auto;
    flex-direction: row;
    align-items: center;
}

.mobileImgContainer {
    height: clamp(250px, 41.6vw, 800px);
}

.styledCol {
    padding: 0px 50px 0px 25px;
    height: 100%;
}

.headingDesk {
    font-size: clamp(25px, 2vw, 40px);
    font-weight: 900;
    line-height: 1.4;
}

.listContainer {
    margin-top: 15px;
    margin-bottom: 15px;
}

.listContainer ul {
    padding: 15px;
}

.listContainer ul li {
    list-style-image: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/listBullet.webp");
    margin-bottom: 10px;
    font-size: clamp(12px, 1.25vw, 24px);
    letter-spacing: -0.32px;
}

.downloadImgContainer {
    flex-wrap: nowrap !important;
    cursor: pointer;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
}

.downloadImg {
    text-align: center;
    width: 100%;
    height: 50px;
    object-fit: contain;
    padding-left: 30px;
    padding-right: 0;
    position: relative;
}

.imgContainer {
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
}

.downloadImg:nth-child(2) {
    padding-left: 0px;
}

.headingMobContainer {
    display: none;
}

@media screen and (max-width: 767px) {
    .container {
        height: 100%;
    }

    .headingMobContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .styledRow {
        height: 100%;
        flex-direction: column;
    }

    .headingMob {
        text-align: center;
        font-size: 16px;
        font-weight: 900;
        font-style: italic;
        line-height: 1.5;
        max-width: 150px;
    }

    .mobileImgContainer {
        min-height: 262px;
    }

    .headingDesk {
        display: none;
    }

    .styledCol {
        padding: 0 17px;
    }

    .listContainer {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .listContainer ul {
        margin: 0;
        padding-inline-start: 20px;
        text-align: center;
        padding: 10px 15px;
    }

    .listContainer ul li {
        text-align: left;
        letter-spacing: -0.32px;
        padding-bottom: 10px;
    }

    .downloadImgContainer > div {
        margin-bottom: 20px;
    }

    .downloadImg {
        padding: 0px 15px;
    }
}